import { Container, Text } from "@shared/elements";
export default function Index() {
  return (
    <Container className="pt-5">
      <Text as="h1" color="primary" size="3xl">
        Ads
      </Text>
    </Container>
  );
}
export async function getStaticProps() {
  return {
    props: {},
  };
}
